// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

function showRecoveryForm()
{
    $('#recovery_form').dialog({
	title: 'Восстановление пароля'
    });
}

function showMessage(message)
{
    $("<div />").html('<p>' +message+ '</p>').dialog({
	title: 'Восстановление пароля'
    });
}

function showChangePasswordForm()
{
    $('#change_password_form').dialog({
        title: 'Восстановление пароля'
    });
}

function showUserSocial(soc, name)
{
    $('#link_user_social').dialog({
        title: 'Соцсеть',
	create: function() {
		$("#msg_soc").text(soc);
    		$("#msg_name").text(name);
	}
    });
}
