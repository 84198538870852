var Hint3Name = '';
var currentModal;
step=0;

function drawLogin(id, login, level, align, clanName, clanImage)
{
    if (gameUrl == undefined)
        gameUrl = '';

    var html = '';

    if (align)
        html += '<img src="/i/align/align' + align + '.gif">';

    if (clanImage)
        html += '<img title="' + clanName + '" src="/i/clans/' + clanImage + '">';

    html += ' <b>' + login +'</b> [' + level + ']';
    html += '<a target="_blank" href="' + gameUrl + '/info/' + id +'"><img title="Инф. о ' + login +'" src="/i/inf.gif"></a>';

    return '<div data-id="' + id + '">' + html + '</div>';
}

// Заголовок, название скрипта, имя поля с логином
function findlogin(title, script, name, defaultlogin) {
    lazyDialog('login', title, script, name, null, defaultlogin);
}

function dialog(title, script, btn, params) {
    checkModal();

    var buttons = {};

    if (btn && btn.yes && btn.yes.name)
        buttons[btn.yes.name] = function() {
            $( this ).find('form').submit();
            $( this ).dialog( "close" );
        };

    if (btn && btn.no && btn.no.name)
        buttons[btn.no.name] = function() {
            if (btn.no.url) {
                var dialog = $(this).closest('.ui-dialog');
                var body = dialog.find('.ui-dialog-content');
                var form = body.find('form');
                form.attr('action', btn.no.url);
                form.submit();
            }
            $( this ).dialog( "close" );
        };

    currentModal = $('<div>').dialog({
        resizable: false,
        width: 600,
        create: function( event, ui ) {
            var dialog = $(this).closest('.ui-dialog');
            var body = dialog.find('.ui-dialog-content');
            body.html('Загрузка...');
            dialog.find('.ui-dialog-title').text(title);

            body.load(script, function() {
                var price = body.find("div#price");
                if (params && params.showPrice) {
                    price.show();
                } else {
                    price.hide();
                }

                if (btn && btn.yes && btn.yes.url) {
                    var form = body.find('form');
                    form.attr('action', btn.yes.url);
                }
                //dialogPos(currentModal);
            });
        },
        buttons: buttons
    });
}

function lazyDialog(use_type, title, script, magickname, n, defaultlogin, extparam, callback, position) {
    checkModal();
    currentModal = $('<div>').dialog({
        resizable: false,
        position: position,
        create: function( event, ui ) {
            var dialog = $(this).closest('.ui-dialog');
            var body = dialog.find('.ui-dialog-content');
            body.html('Загрузка...');
            dialog.find('.ui-dialog-title').text(title);

            var param = '';
            if (n)
                param = '?n=' + n;

            body.load('/align/' + use_type + param, function() {
                var form = body.find('form');
                form.attr('action', script);
                form.find("input[name=n]").val(n);
                form.find("input[name=use]").val(magickname);
                var focusInput = form.find("[name=param]").focus();
                if (defaultlogin) {
                    focusInput.val(defaultlogin);
                }

                if (callback) {
                    callback(currentModal, form);
                }
              //  dialogPos(currentModal, position);

                Hint3Name = 'param';
            });
        },
        close: function( event, ui ) {
            Hint3Name = null;
        }
    });
}

function dialogPos(modal, position)
{
    if (!position) {
        var parent;
        //if ((self.frameElement && (self.frameElement+"").indexOf("HTMLIFrameElement") > -1)) {
        //    parent = top.frames['main'].document;
        //} else {
            parent = document;
        //}

        position = {
            my: "center",
            at: "center",
            of: parent
        };
    }

    modal.dialog('option', 'position', position);
}

function dialogEnterCount(title, script, magickname, n, defaultlogin)
{
    lazyDialog('count', title, script, magickname, n, defaultlogin);
}

function dialogLoginGiveEkrAccount(use_type, title, script, magickname, n, defaultlogin, extparam) {
    lazyDialog(use_type, title, script, magickname, n, defaultlogin, extparam, function (modal, form) {
        var param = form.find("input[name=param]");
        var options = form.find('select');
        param.blur(function() {
            var newLogin = $(this).val();
            options.empty();
            $.get('/ajax/accounts?login=' + newLogin, function (data) {
                $.each(data.accounts, function(i, account) {
                    options.append($("<option />").val(account).text(account));
                });
            }, 'json');
        });
    });
}

function dialogConfirm(title, script, text, n, use, forceHideConfirm, callback, position) {
    checkModal();

    currentModal = $('<div>').dialog({
        position: position,
        resizable: false,
        create: function( event, ui ) {
            var dialog = $(this).closest('.ui-dialog');
            var body = dialog.find('.ui-dialog-content');
            body.html('Загрузка...');
            dialog.find('.ui-dialog-title').text(title);

            body.load('/align/confirm', function() {
                var conf = body.find("label.do_not_ask_again");
                var form = body.find('form');
                form
                    .attr('action', script)
                    .attr('id', 'dialog_confirm_'+n);

                if (n && forceHideConfirm == undefined) {
                    conf.show();
                } else {
                    conf.hide();
                }
                form.find("input[name=n]").val(n);
                form.find("input[name=use]").val(use);

                body.find("p").html(text);

                if (callback) {
                    callback(currentModal, form);
                }
            });
        },
        buttons: {
            "Да": function() {
                $( this ).find('form').submit();
                $( this ).dialog( "close" );
            },
            "Нет": function() {
                $( this ).dialog( "close" );
            }
        }
    });
}

function checkModal()
{
    if (currentModal) {
        currentModal.dialog( "destroy" );
        currentModal = null;
    }
}

// Для магии. Заголовок, название скрипта, название магии, номер вещицы в рюкзаке, логин по умолчанию, описание доп. поля
function magicklogin(title, script, magickname, n, defaultlogin, extparam, callback, position)
{
    lazyDialog('login', title, script, magickname, n, defaultlogin, extparam, callback, position);
}

function FindItemsForSocketing(title, script, name, extparam, n, extparam2)
{
    SuggestionModal(title, script, name, extparam, n, extparam2, function(modal)
    {
        $.get('/equip/for_socketing?n' + n, function(response) {
            var select = $('select', modal);
            select.append( $('<option />').html('-') );
            $.each(response, function(val, text) {
                select.append( $('<option />').val(val).html(text) )
            });
        }, 'json');
    });
}

function FindItemsForIdentity(title, script, name, extparam, n, extparam2)
{
    SuggestionModal(title, script, name, extparam, n, extparam2, function(modal)
    {
        $.get('/equip/for_identity?n' + n, function(response) {
            var select = $('select', modal);
            $.each(response, function(val, text) {
                select.append( $('<option />').val(val).html(text) )
            });
        }, 'json');
    });
}

function FindWeaponsForSharpen(title, script, name, extparam, n, extparam2)
{
    SuggestionModal(title, script, name, extparam, n, extparam2, function(modal)
    {
        $.get('/equip/for_sharpen?n=' + n, function(response) {
            var select = $('select', modal);
            $.each(response, function(val, text) {
                select.append( $('<option />').val(val).html(text) )
            });
        }, 'json');
    });
}

function FindSocketedItems(title, script, name, extparam, n, extparam2)
{
    SuggestionModal(title, script, name, extparam, n, extparam2, function(modal)
    {
        $.get('/equip/socketed?n=' + n, function(response) {
            var select = $('select', modal);
            $.each(response, function(val, text) {
                select.append( $('<option />').val(val).html(text) )
            });
        }, 'json');
    });
}

function SuggestionModal(title, script, name, extparam, n, extparam2, callback)
{
    lazyDialog('suggestion', title, script, name, n, null, extparam, function(modal, form) {
        callback(modal);
    });
}

// Магия
function UseMagick(title, script, name, extparam, n, extparam2, do_not_ask, position) {
    if ((extparam != null)&&(extparam != '')) {
        lazyDialog('magic', title, script, name, n);
    } else {
        var question;
        if ((extparam2 != null)&&(extparam2 != '')) {
            question = extparam2;
        } else {
            question = 'Использовать сейчас';
        }
        dialogConfirm(
            'Подтверждение',
            script,
            '<table width="100%"><tr><td><img src="/i/items/'+name+'"></td><td>'+question+'?</td></tr></table>',
            n,
            name,
            undefined,
            function(modal, form) {
                if (do_not_ask) {
                    form.submit();
                }
            },
            position);
    }
}

function stack(itemImage, itemName, itemId)
{
    dialogConfirm(
        "Собрать предметы?",
        '/equip/stack',
        '<TABLE width=100%><TD><IMG src="/i/items/'+itemImage+'"></TD><TD>Собрать предметы <B>"' + itemName + '"</B></TABLE>',
        itemId,
        null,
        true
    );
}

function stackCraft(itemImage, itemName, itemId)
{
    dialogConfirm(
        "Собрать предмет?",
        '/equip/craft',
        '<TABLE width=100%><TD><IMG src="/i/items/'+itemImage+'"></TD><TD>Собрать предмет <B>"' + itemName + '"</B></TABLE>',
        itemId,
        null,
        true
    );
}

function unstack(itemImage, itemName, itemId)
{
    dialogConfirm(
        "Отделить предмет?",
        '/equip/unstack',
        '<TABLE width=100%><TD><IMG src="/i/items/'+itemImage+'"></TD><TD>Отделить предмет <B>"'+itemName+'"</B></TABLE>Кол-во: <INPUT type=text name="unstack" value="1">',
        itemId,
        null,
        true
    );
}

function splitstack(itemImage, itemName, itemId)
{
    dialogConfirm(
        "Разделить на равные?",
        "/equip/splitstack",
        '<TABLE width=100%><TD><IMG src="/i/items/'+itemImage+'"></TD><TD>Разделить на равные части <B>"'+itemName+'"</B></TABLE>Кол-во частей: <INPUT type=text name="splitstack" value="2" />',
        itemId,
        null,
        true
    )
}

function AdvancedStatsEditor(obj) {
    function inputChange(self) {
        var elem    = $(self);
        var data    = elem.data();
        var def     = parseInt(data['default']);
        var old     = parseInt(data['old']);
        var now     = parseInt(elem.val());
        var free    = parseInt(elem.closest('form').find('#free').text());
        var max     = parseInt(old + free);
        now = now > max ? max : now;
        free = (now > old)? free - (now - old) : free + (old - now);
        if (now < def) {
            elem.val(def);
            return;
        }
        if (free < 0) {
            elem.val(old);
            elem.closest('form').find('#free').text('0');
            return;
        }
        elem.data('old', now).val(now);
        elem.closest('form').find('#free').text(free);
    }
    var form = $(obj).closest('form');
    var free = parseInt(form.find('#free').text());
    form.find('div#stat').each(function(index, elem) {
        var elem = $(elem);
        var val = elem.find('#val');
        var id = val.data('id');
        var plus = $('<img src="/i/plus.gif" id="plus" style="margin-right: 1px; cursor: pointer;" />');
        var minus = $('<img src="/i/minus.gif" id="minus" style="margin-left: 1px; cursor: pointer;" />');
        var btns = elem.find('#btns');
        if (!val.has('input').length) {
            var text = parseInt(val.text());
            var input = $('<input type="number" rel="prep" style="width: 40px;" data-old="'+text+'" data-default="'+text+'" min="'+text+'" value="'+text+'" name="'+id+'">').change(function() {
                inputChange(this);
            });

            val.html('').append(input);
            plus.click(function() {
                var v = parseInt(val.find('input').val());
                val.find('input').val(v+1);
                input.trigger('change');
            });
            minus.click(function() {
                var v = parseInt(val.find('input').val());
                val.find('input').val(v-1);
                input.trigger('change');
            });
            btns.find('a').hide();
            btns.append(plus, minus);
        } else {
            var inputVal = parseInt(val.find('input').val());
            var def = parseInt(val.find('input').data('default'));
            if (inputVal > def) {
                free += inputVal - def;
            }
            btns.find('img#plus').remove();
            btns.find('img#minus').remove();
            btns.find('a').show();
            val.html(def);
        }
    });

    form.find('#free').text(free);
    form.find('#btns_toggle').toggle();
    form.submit(function() {
        form.find('div#stat').each(function(index, elem) {
            if ($(elem).find('#val').has('input').length) {
                var elem = $(elem).find('#val input');
                var name = elem.attr('name')
                elem.attr('name', 'old_' + name);
                var def = parseInt(elem.data('default'));
                var val = parseInt(elem.val());
                var diff = val - def;

                if (diff > 0) {
                    $('<input />')
                        .attr('type', 'hidden')
                        .attr('name', name)
                        .val(diff)
                        .appendTo(form);
                }
            }
        });
    });
    return false;
}
